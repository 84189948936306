
.tell-friends-block {
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 12px;
  border-radius: 8px;
  .tell-friends {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    .form-fields {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      width: 100%;
      border: 1px solid #e0e0e0;
      padding: 5px 10px;
      span {
        width: 100%;
        max-width: 22px;
        min-width: 22px;
        line-height: 0px;
        margin-right: 15px;
        opacity: 0.3;
        img {
          max-width: 100%;
        }
      }
      input.custom-input {
        width: 100%;
        border: none;
        outline: none;
        box-shadow: none;
        height: 30px;
        font-size: 16px;
      }
    }
    .form-fields + .form-fields {
      margin-left: 10px;
      @media all and (max-width: 767px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    button {
      background-color: #666dff;
      border-radius: 4px;
      white-space: nowrap;
      padding: 9px 20px;
      color: #fff;
      font-size: 16px;
      text-transform: capitalize;
      height: 42px;
      margin-left: 10px;
      @media all and (max-width: 767px) {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
        font-weight: 600;
      }
      &.disabled {
        background-color: #b3b7fd;
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.3);
        pointer-events: none;
      }
    }
    // a {
    //   @media all and (max-width: 767px) {
    //     width: 100%;
    //   }
    // }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
  }
}
